import React, { useEffect, useRef } from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../components/Layout";
import MetaComponents from "../components/seo/MetaComponents";
import Article from "../components/Article";
import ArticleHeader from "../components/ArticleHeader";

const Datenschutzerklaerung = () => {
    const { t } = useTranslation();
    const cookieRef = useRef(null);

    useEffect(() => {
        if (window.location.hash === "#cookies") {
            cookieRef.current.scrollIntoView();
        }
    }, [cookieRef]);

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Datenschutzerklärung Miracl GmbH" />
            <Article>
                <h2>§ 1 Einleitung</h2>
                <p>
                    (1) Mit dieser Datenschutzerklärung informieren wir Sie über die Erhebung personenbezogener Daten
                    bei der Nutzung unserer Website und der Inanspruchnahme unserer Leistungen. Personenbezogene Daten
                    sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person
                    beziehen, z.B. Name, Adresse, Geburtsdatum, E-Mail-Adresse, etc.
                </p>

                <h2>§ 2 Verantwortlicher / Datenschutzbeauftragter</h2>
                <p>
                    (1) <strong>Verantwortlicher</strong> gemäß Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung [„
                    <strong>DSGVO</strong>“] ist <strong>Miracl GmbH</strong>, [Lindengasse 26/1/5, 1070 Wien], E-Mail:{" "}
                    <a href="mailto:kontakt@miracl.at">kontakt@miracl.at</a> (siehe auch unser{" "}
                    <Link to="/impressum/" target="_blank" rel="noreferrer noopener">
                        Impressum
                    </Link>
                    ).
                </p>

                <h2>§ 3 Allgemeine Informationen zur Verarbeitung personenbezogener Daten</h2>
                <p>
                    (1) Wir sind die Betreiber einer Plattform, die die Nutzer beim Erwerb einer Immobilie unterstützt,
                    insbesondere durch Vermittlung von Krediten, Sachverständigen und Rechtsanwälten.
                </p>
                <p>
                    (2) Alle personenbezogene Daten werden von uns nur insoweit erhoben und verarbeitet, als dies für
                    die Bereitstellung der Website, die Beantwortung von Anfragen, die Bearbeitung
                    <br />
                    und Abwicklung der uns erteilten Aufträge/mit uns abgeschlossenen Verträge (gemeinsam kurz „
                    <strong>Vertragsverhältnisse</strong>“) notwendig ist, oder um Ihnen Zugang zu bestimmten
                    Informationen und Angeboten zu verschaffen. Die personenbezogenen Daten werden von uns
                    ausschließlich zum entsprechenden Zweck und unter Beachtung der geltenden datenschutzrechtlichen
                    Bestimmungen genutzt.
                </p>
                <p>(3) Rechtsgrundlage für die Verarbeitung personenbezogener Daten ist Art. 6 DSGVO:</p>
                <p>
                    (a) Erfolgt die Verarbeitung personenbezogener Daten aufgrund einer <strong>Einwilligung</strong>{" "}
                    der betroffenen Person, ist Art. 6 Abs. 1 lit. a) DSGVO die Rechtsgrundlage.
                    <br />
                    (b) Ist die Verarbeitung personenbezogener Daten für die <strong>Erfüllung eines Vertrages</strong>,
                    dessen Vertragspartei die betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen
                    erforderlich, die auf Anfrage der betroffenen Person erfolgen, ist Art. 6 Abs. 1 lit. b) DSGVO die
                    Rechtsgrundlage.
                    <br />
                    (c) Ist die Verarbeitung personenbezogener Daten zur{" "}
                    <strong>Erfüllung einer rechtlichen Verpflichtung</strong> erforderlich, der wir als
                    Verantwortlicher unterliegen, ist Art. 6 Abs. 1 lit. c) DSGVO die Rechtsgrundlage.
                    <br />
                    (d) Ist die Verarbeitung personenbezogener Daten erforderlich, um{" "}
                    <strong>lebenswichtige Interessen</strong> der betroffenen Person oder einer anderen natürlichen
                    Person zu schützen, ist Art. 6 Abs. 1 lit. d) DSGVO die Rechtsgrundlage.
                    <br />
                    (e) Ist die Verarbeitung personenbezogener Daten für die <strong>
                        Wahrnehmung einer Aufgabe
                    </strong>{" "}
                    erforderlich, die im <strong>öffentlichen Interesse</strong> liegt oder in Ausübung öffentlicher
                    Gewalt erfolgt, die uns übertragen worden ist, ist Art. 6 Abs. 1 lit. e) DSGVO die Rechtsgrundlage.
                    <br />
                    (f) Ist die Verarbeitung personenbezogener Daten zur{" "}
                    <strong>Wahrung unserer berechtigten Interessen</strong> oder eines Dritten erforderlich, sofern
                    nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person überwiegen, ist
                    Art. 6 Abs. 1 lit. f) DSGVO die Rechtsgrundlage.
                </p>
                <p>
                    (4) Die anfallenden personenbezogenen Daten werden von uns nur solange gespeichert, wie es für die
                    Zwecke erforderlich ist, für die wir die personenbezogenen Daten erhoben haben. Eine Speicherung
                    darüber hinaus kann dann erfolgen, wenn eine Speicherung aufgrund von europäischen oder nationalen
                    Rechtsvorschriften, denen wir unterliegen, vorgesehen ist.
                </p>
                <p>
                    (5) Hinsichtlich der Sie betreffenden personenbezogenen Daten haben Sie gegenüber uns folgende
                    Rechte:
                </p>
                <ul>
                    <li>Recht auf Auskunft (Art. 15 DSGVO)</li>
                    <li>Recht auf Berichtigung (Art. 16 DSGVO)</li>
                    <li>Recht auf Löschung (Art. 17 DSGVO)</li>
                    <li>Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)</li>
                    <li>Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</li>
                    <li>Recht auf Widerspruch (Art. 21 DSGVO)</li>
                </ul>
                <p>
                    (6) Auch weisen wir Sie darauf hin, dass Ihnen ein Beschwerderecht bei einer zuständigen
                    Datenschutz-Aufsichtsbehörde zusteht. In Österreich ist dies die Datenschutzbehörde (1030 Wien,
                    Barichgasse 40-42).
                </p>

                <h2>§ 4 Empfänger Ihrer personenbezogenen Daten</h2>
                <p>(1) Ihre personenbezogenen Daten können weitergegeben werden an:</p>
                <ul>
                    <li>
                        Öffentliche Stellen und Institutionen sowie Personen im hoheitlichen Auftrag, sofern wir
                        rechtlich dazu verpflichtet sind oder um unsere berechtigten Interessen zu wahren
                        (Finanzbehörden, Strafverfolgungsbehörden etc.)
                    </li>
                    <li>
                        Dritte, sofern es für die Vertragserfüllung oder rechtlichen Vorschriften verpflichtend ist,
                        z.B. Kreditvermittler, Immobiliensachverständige, Rechtsanwälte
                    </li>
                    <li>
                        Von uns beauftragte Auftragsverarbeiter und sonstige Dienstleister, z.B. für IT, Backoffice,
                        Rechts- und Steuerberatung, etc.
                    </li>
                </ul>
                <p>
                    (2) Im Rahmen der Vertragsbeziehung und insbesondere in Zusammenhang mit unserer
                    Leistungsverpflichtung, kann es – je nach Einzelfall – zu weiteren Übermittlungen Ihrer
                    personenbezogenen Daten kommen (z.B. Kreditinstitute).
                </p>

                <h2>§ 5 Kontaktaufnahme und Beantwortung von Anfragen</h2>
                <p>
                    (1) Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein Kontaktformular werden die von Ihnen
                    mitgeteilten Daten (E-Mail-Adresse, allenfalls Name und Telefonnummer) von uns gespeichert, um Ihre
                    Anfragen zu beantworten.
                </p>
                <p>
                    (2) Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr
                    erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten
                    bestehen.
                </p>

                <h2>
                    § 6 Erhebung und Verarbeitung von personenbezogenen Daten zur Abwicklung der Vertragsverhältnisse
                </h2>
                <p>
                    (1) Soweit Sie uns personenbezogene Daten zur Verfügung gestellt haben, verwenden wir diese
                    ausschließlich zur Abwicklung und Erfüllung der Vertragsverhältnisse.
                </p>
                <p>
                    (2) Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr
                    erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten
                    bestehen.
                </p>

                <h2 ref={cookieRef}>§ 7 Cookies</h2>
                <p>
                    Bei Ihrer Nutzung unserer Website können Cookies auf Ihrem Betriebssystem gespeichert werden. Bei
                    Cookies handelt es sich um kleine Textdateien, die im Internet-Browser bzw. vom Internet-Browser des
                    Nutzers gespeichert werden. Cookies können keine Programme ausführen oder Viren auf Ihr
                    Betriebssystem bzw. Ihren Computer übertragen. Cookies sollen das Internetangebot nutzerfreundlicher
                    und effektiver machen. Ihre Browser-Einstellungen können Sie entsprechend Ihren Vorstellungen
                    anpassen und daher auch die Annahme von bestimmten oder auch allen Cookies ablehnen. In diesem Fall
                    ist es jedoch möglich, dass Sie eventuell nicht alle Funktionen unserer Website nutzen können.
                </p>
                <p>
                    <em>
                        Schließlich werden Cookies von uns auch dafür eingesetzt, um Sie für weitere Besuche
                        identifizieren zu können, falls Sie über einen Account bei uns verfügen. Andernfalls müssen Sie
                        sich bei jedem Besuch erneut einloggen.
                    </em>
                </p>
                <p>
                    Die auf der Website verwendeten Cookies sind kategorisiert. Nachfolgend können Sie Informationen zu
                    den einzelnen Kategorien lesen und einige oder alle zulassen oder ablehnen. Wenn Kategorien
                    deaktiviert sind, die zuvor zugelassen wurden, werden alle dieser Kategorie zugewiesenen Cookies aus
                    Ihrem Browser entfernt. Zusätzlich sehen Sie eine Cookies-Liste und jede zugeordnete Kategorie und
                    weitere Informationen in der Cookie-Erklärung.
                </p>
                <p>
                    Notwendige Cookies:
                    <p>
                        Notwendige Cookies stellen die Kernfunktionalitäten dieser Webseite zur Verfügung. Ohne diese
                        Cookies kann die Website nicht richtig funktionieren. Sie sind normalerweise aktiviert und
                        können nicht deaktiviert werden.
                    </p>
                    <ul>
                        <li>user_id</li>
                        <li>
                            __cf_bm: The __cf_bm cookie supports Cloudflare Bot Management by managing incoming traffic
                            that matches criteria associated with bots. The cookie does not collect any personal data,
                            and any information collected is subject to one-way encryption.
                        </li>
                        <li>
                            JSESSIONID: General purpose platform session cookie, used by sites with JavaServer Pages
                            (JSP). The cookie is usually used to maintain an anonymous user session by the server.
                        </li>
                        <li>_GRECAPTCHA: Used by Google reCaptcha for risk analysis</li>
                        <li>
                            cookiehub: Used by CookieHub to store information about whether visitors have given or
                            declined the use of cookie categories used on the site.
                        </li>
                    </ul>
                </p>
                <p>
                    Benutzereinstellungen:
                    <p>
                        Cookies ermöglichen es uns darüber hinaus, Informationen über die Benutzung zu erhalten und
                        unsere website den Anforderungen unserer Besucher anzupassen und zu optimieren. Dies kann das
                        Speichern ausgewählter Währungen, Regionen, Sprachen oder Farbthemen beinhalten.
                    </p>
                    <ul>
                        <li>miracl-cookiehub-necessary</li>
                        <li>miracl-cookiehub-analytics</li>
                        <li>miracl-cookiehub-marketing</li>
                        <li>
                            messagesUtk: This cookie is used to recognize visitors who chat with you via the chatflows
                            tool. If the visitor leaves your site before they're added as a contact, they will have this
                            cookie associated with their browser.
                        </li>
                        <li>miracl-cookiehub-preferences</li>
                    </ul>
                </p>
                <p>
                    Analytische Cookies:
                    <p>
                        Analytische Cookies helfen uns, unsere Website zu verbessern, indem sie Informationen über ihre
                        Nutzung sammeln und melden.
                    </p>
                    <ul>
                        <li>
                            _ga: Contains a unique identifier used by Google Analytics to determine that two distinct
                            hits belong to the same user across browsing sessions.
                        </li>
                        <li>
                            _gid: Contains a unique identifier used by Google Analytics to determine that two distinct
                            hits belong to the same user across browsing sessions.
                        </li>
                        <li>
                            _gat_*: Used by Google Analytics to throttle request rate (limit the collection of data on
                            high traffic sites)
                        </li>
                        <li>_hjFirstSeen</li>
                        <li>
                            __hstc: This cookie name is associated with websites built on the HubSpot platform. This is
                            the main cookie for tracking visitors. It contains the domain, utk, initial timestamp (first
                            visit), last timestamp (last visit), current timestamp (this visit), and session number
                            (increments for each subsequent session).
                        </li>
                        <li>
                            hubspotutk: This cookie name is associated with websites built on the HubSpot platform. This
                            cookie is used to keep track of a visitor's identity. This cookie is passed to HubSpot on
                            form submission and used when deduplicating contacts.
                        </li>
                        <li>
                            __hssrc: This cookie name is associated with websites built on the HubSpot platform.
                            Whenever HubSpot changes the session cookie, this cookie is also set to determine if the
                            visitor has restarted their browser. If this cookie does not exist when HubSpot manages
                            cookies, it is considered a new session.
                        </li>
                        <li>
                            __hssc: This cookie name is associated with websites built on the HubSpot platform. This
                            cookie keeps track of sessions. This is used to determine if HubSpot should increment the
                            session number and timestamps in the __hstc cookie. It contains the domain, viewCount
                            (increments each pageView in a session), and session start timestamp.
                        </li>
                        <li>
                            _hjIncludedInPageviewSample: This cookie is set to let Hotjar know whether that visitor is
                            included in the data sampling defined by your site's pageview limit.
                        </li>
                        <li>
                            _hjAbsoluteSessionInProgress: The cookie is set so Hotjar can track the beginning of the
                            user's journey for a total session count. It does not contain any identifiable information.
                        </li>
                        <li>
                            _hjIncludedInSessionSample: This cookie is set to let Hotjar know whether that visitor is
                            included in the data sampling defined by your site's daily session limit.
                        </li>
                        <li>landing_page</li>
                        <li>_hjSessionUser_2500316</li>
                        <li>_hjSession_2500316</li>
                        <li>
                            _ga_*: Contains a unique identifier used by Google Analytics 4 to determine that two
                            distinct hits belong to the same user across browsing sessions.
                        </li>
                        <li>
                            _hjCachedUserAttributes: This cookie stores User Attributes which are sent through the
                            Hotjar Identify API, whenever the user is not in the sample. These attributes will only be
                            saved if the user interacts with a Hotjar Feedback tool.
                        </li>
                    </ul>
                </p>
                <p>
                    Marketing Cookies:
                    <p>
                        Mithilfe von Marketing-Cookies werden Besucher über Websites hinweg verfolgt, um relevante und
                        ansprechende Werbung anzuzeigen.
                    </p>
                    <ul>
                        <li>
                            _gcl_au: Used by Google AdSense to understand user interaction with the website by
                            generating analytical data.
                        </li>
                        <li>test_cookie: Used to check if the user's browser supports cookies</li>
                        <li>
                            _fbp: Facebook Pixel advertising first-party cookie. Used by Facebook to track visits across
                            websites to deliver a series of advertisement products such as real time bidding from third
                            party advertisers.
                        </li>
                        <li>
                            IDE: Used by Google's DoubleClick to serve targeted advertisements that are relevant to
                            users across the web. Targeted advertisements may be displayed to users based on previous
                            visits to a website. These cookies measure the conversion rate of ads presented to the user.
                        </li>
                    </ul>
                </p>

                <p>(1) SSL-Zertifikat</p>
                <p>
                    Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie
                    zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
                    SSL-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
                    Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn
                    die SSL-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von
                    Dritten mitgelesen werden.
                </p>
                <p>(2) Google Analytics</p>
                <p>
                    Wir setzen auf Grundlage unserer berechtigten Interessen einen Webanalysedienst der Google LLC
                    („Google“) ein. Google verwendet Cookies. Die durch das Cookie erzeugten Informationen über
                    Benutzung des Onlineangebotes durch die Nutzer werden in der Regel an einen Server von Google in den
                    USA übertragen und dort gespeichert.
                </p>
                <p>
                    Google wird diese Informationen in unserem Auftrag benutzen, um die Nutzung unseres Onlineangebotes
                    durch die Nutzer auszuwerten, um Reports über die Aktivitäten innerhalb dieses Onlineangebotes
                    zusammenzustellen und um weitere, mit der Nutzung dieses Onlineangebotes und der Internetnutzung
                    verbundene Dienstleistungen, uns gegenüber zu erbringen. Dabei können aus den verarbeiteten Daten
                    pseudonyme Nutzungsprofile der Nutzer erstellt werden.
                </p>
                <p>
                    Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. Das bedeutet, die IP-Adresse
                    der Nutzer wird von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
                    Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen
                    wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.
                </p>
                <p>
                    Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit anderen Daten von Google
                    zusammengeführt. Die Nutzer können die Speicherung der Cookies durch eine entsprechende Einstellung
                    ihrer Browser-Software verhindern. Die Nutzer können darüber hinaus die Erfassung der durch das
                    Cookie erzeugten und auf ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie die
                    Verarbeitung dieser Daten durch Google verhindern, indem sie das unter folgendem Link verfügbare
                    Browser-Plugin herunterladen und installieren:{" "}
                    <a
                        href="https://tools.google.com/dlpage/gaoptout?hl=de"
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                    >
                        https://tools.google.com/dlpage/gaoptout?hl=de
                    </a>
                    .
                </p>
                <p>
                    Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten,
                    erfahren Sie in der Datenschutzerklärung von Google (
                    <a
                        href="https://policies.google.com/technologies/partner-sites"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        https://policies.google.com/technologies/partner-sites
                    </a>
                    ) sowie in den Einstellungen für die Darstellung von Werbeeinblendungen durch Google (
                    <a
                        href="https://adssettings.google.com/authenticated"
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                    >
                        https://adssettings.google.com/authenticated
                    </a>
                    ).
                </p>

                <p>(3) Hotjar</p>
                <p>
                    Wir nutzen Hotjar, um die Bedürfnisse unserer Nutzer besser zu verstehen und das Angebot und die
                    Erfahrung auf dieser Webseite zu optimieren. Mithilfe der Technologie von Hotjar bekommen wir ein
                    besseres Verständnis von den Erfahrungen unserer Nutzer (z.B. wieviel Zeit Nutzer auf welchen Seiten
                    verbringen, welche Links sie anklicken, was sie mögen und was nicht etc.) und das hilft uns, unser
                    Angebot am Feedback unserer Nutzer auszurichten.
                    <br />
                    <br />
                    Hotjar arbeitet mit Cookies und anderen Technologien, um Daten über das Verhalten unserer Nutzer und
                    über ihre Endgeräte zu erheben, insbesondere IP Adresse des Geräts (wird während Ihrer
                    Website-Nutzung nur in anonymisierter Form erfasst und gespeichert), Bildschirmgröße, Gerätetyp
                    (Unique Device Identifiers), Informationen über den verwendeten Browser, Standort (nur Land), zum
                    Anzeigen unserer Webseite bevorzugte Sprache.
                    <br />
                    <br />
                    Hotjar speichert diese Informationen in unserem Auftrag in einem pseudonymisierten Nutzerprofil.
                    Hotjar ist es vertraglich verboten, die in unserem Auftrag erhobenen Daten zu verkaufen.
                    <br />
                    <br />
                    Weitere Informationen finden Sie in unter dem Abschnitt ‚About Hotjar‘ auf{" "}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        content="noindex,nofollow"
                        href={"https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar"}
                    >
                        Hotjars Hilfe-Seite
                    </a>
                    .
                </p>

                <h2>§ 8 Drittanbieter</h2>
                <p>
                    <b>Verarbeitung personenbezogener Daten im Rahmen der Kundenkommunikation:</b>
                    <br />
                    Die miracl GmbH nutzt KI-gestützte Technologien zur effizienten Bearbeitung von Kundenanfragen und
                    zur Verwaltung von Kundenbeziehungen. Diese Systeme sind mit unserem
                    Customer-Relationship-Management (CRM) integriert, um eine schnelle und präzise Bearbeitung Ihrer
                    Anliegen zu gewährleisten.
                </p>
                <p>
                    <b>Zweck der Datenverarbeitung:</b>
                    <br />
                    Die Verarbeitung Ihrer personenbezogenen Daten erfolgt ausschließlich zur Erfüllung unserer
                    vertraglichen Pflichten Ihnen gegenüber, insbesondere zur Beantwortung von Anfragen, zur
                    Bereitstellung unserer Dienstleistungen und zur Abwicklung von Finanzierungsanfragen.
                </p>
                <p>
                    <b>Rechtsgrundlage der Verarbeitung:</b>
                    <br />
                    Die Verarbeitung basiert auf Artikel 6 Absatz 1 Buchstabe b DSGVO, da sie für die Erfüllung des
                    Vertrags zwischen Ihnen und der miracl GmbH oder zur Durchführung vorvertraglicher Maßnahmen
                    erforderlich ist.
                </p>
                <p>
                    <b>Art der verarbeiteten Daten:</b>
                    <ul>
                        <li>Name und Kontaktdaten (wie E-Mail-Adresse, Telefonnummer)</li>
                        <li>Inhalte der Kommunikation mit Ihnen</li>
                        <li>Daten zu Ihren Finanzierungsanfragen und -verträgen</li>
                    </ul>
                </p>
                <p>
                    <b>Datenweitergabe und Sicherheit:</b>
                    <br />
                    Ihre Daten werden ausschließlich zu den genannten Zwecken verarbeitet und nicht an Dritte
                    weitergegeben, es sei denn, dies ist zur Vertragserfüllung erforderlich oder es besteht eine
                    gesetzliche Verpflichtung. Wir setzen angemessene technische und organisatorische Maßnahmen ein, um
                    die Sicherheit Ihrer Daten zu gewährleisten und sie vor unbefugtem Zugriff zu schützen.
                </p>
                <p>
                    <b>Ihre Rechte:</b>
                    <br />
                    Sie haben das Recht auf Auskunft über die bei uns gespeicherten personenbezogenen Daten, auf
                    Berichtigung unrichtiger Daten, auf Löschung oder Einschränkung der Verarbeitung sowie ein
                    Widerspruchsrecht gegen die Verarbeitung. Zudem steht Ihnen das Recht auf Datenübertragbarkeit zu.
                    Für Anliegen dieser Art können Sie sich an uns unter datenschutz@miracl.at wenden.
                </p>
                <p>
                    Wir verwenden für bestimmte Dienstleistungen, einschließlich der Verarbeitung von Dokumenten und
                    Texterkennung (OCR), Drittanbieter. Im Rahmen dieser Verarbeitung nutzen wir die Dienstleistungen
                    von OpenAI und ConvertAPI, um eine präzise Erkennung und Verarbeitung der hochgeladenen Dokumente zu
                    gewährleisten.
                </p>
                <p>
                    Durch die Nutzung unserer Dienste stimmen Sie der Übermittlung und Verarbeitung Ihrer Daten durch
                    diese Drittanbieter zu. Die Nutzung dieser Dienste erfolgt ausschließlich zur Erfüllung der von
                    Ihnen in Auftrag gegebenen Leistungen. Die Daten werden gemäß den Datenschutzrichtlinien dieser
                    Anbieter verarbeitet und sind durch entsprechende Vereinbarungen gesichert.
                </p>

                <h2>§ 9 Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten</h2>
                <p>
                    (1) Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben, können Sie diese
                    jederzeit widerrufen. Ein solcher Widerruf beeinflusst die Zulässigkeit der Verarbeitung Ihrer
                    personenbezogenen Daten, nachdem Sie ihn gegenüber uns ausgesprochen haben. Die Rechtmäßigkeit der
                    aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung bleibt durch einen Widerruf
                    unberührt.
                </p>
                <p>
                    (2) Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessensabwägung stützen,
                    können Sie Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn die Verarbeitung
                    insbesondere nicht zur Erfüllung eines Vertrags mit Ihnen erforderlich ist. Bei Ausübung eines
                    solchen Widerspruchs bitten wir um Darlegung der Gründe, weshalb wir Ihre personenbezogenen Daten
                    nicht wie von uns durchgeführt verarbeiten sollten. Im Falle Ihres begründeten Widerspruchs prüfen
                    wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen
                    unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen.
                </p>

                <h2>§ 10 Änderung dieser Datenschutzerklärung</h2>
                <p>
                    (1) Wir behalten uns das Recht vor, diese Datenschutzerklärung anzupassen und zu aktualisieren,
                    damit diese den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen
                    in der Datenschutzerklärung angemessen abzubilden bzw. umzusetzen. Bei einem Besuch unserer Website
                    bzw. der Inanspruchnahme unserer Leistungen gilt die jeweilige zu diesem Zeitpunkt aktuelle Version
                    der Datenschutzerklärung.
                </p>
            </Article>
        </Layout>
    );
};

export default Datenschutzerklaerung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["header", "footer", "page.datenschutzerklaerung"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
